/************** Main Game Features **************/
.game {}

#game-container {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    /* overflow: hidden; caused problems on smaller phones*/
}

#game-main-title {
    font-weight: bold;
    font-size: 2em;
    background: var(--secondary-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    margin-top: .1em;
}
@media (max-width: 960px) {
    #game-main-title {
        margin-top: .3em;
        margin-bottom: .2em;
        font-size: 3em;
    }
}

#permaximize-message-block {
    display: inline-flex;
}
@media (max-width: 960px) {
    #game-main-msg {
        margin-top: 1.25em;
    }
}
#game-main-msg {
    font-weight: bold;
    color: var(--secondary-text);
    margin: .25em auto;
    height: 2vh;
}
#permaximize-status-indicator {
    margin: 15px 0 0 15px;
}

#game-help-button {
    border: 6px solid;
    border-image-source: var(--light-gradient);
    border-image-slice: 1;

    background: var(--light-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    padding: 0 .25em;
    font-size: 1em;
    font-weight: bold;

    position: fixed;
    right: 1em;
    top: 1em;
    margin-right: 1em;
}

.game-progress-bar {
    margin: 20px auto;
    width: calc(2.5 * 7em);
    justify-content: center;
}

#game-progress-final-1 {
    font-weight: bold;
    color: var(--primary);
}

#game-progress-final-2 {
    font-weight: bold;
    color: var(--secondary);
}

.game-board-grid {
    display: grid;
    align-items: center;
    justify-content: center;
}


/************** Score header **************/
.game-score-header {
    justify-content: center;
    display: flex;
    flex-direction: row;
    font-size: 2em;
    align-items: baseline;
    height: 3.5em;
    margin-top: .5em;
    margin-bottom: -.3em;
    max-height: 2.5em;
}
@media (max-width: 960px) {
    .game-score-header {
        margin-bottom: 1em;
        margin-top: 1em;
    }
}


.game-score {
    padding: .1em .35em;
    border: 10px solid white !important;
    border-radius: 15%;
    margin-top: -.25em;
    margin-bottom: .1em;
    font-size: 1em;
    font-weight: bold;
    min-width: 1.5em;
}
@media (max-width: 960px) {
    .game-score {
        font-size: 1.5em;
        border-radius: 10%;
        min-width: 20vw;
    }
}

.game-score-text {
    margin: 0 auto;
}

#game-score-1 {
    color: var(--primary);
    border-color: var(--primary);
}

#game-score-2 {
    color: var(--secondary);
    border-color: var(--secondary);
}

/* Current player indicator */
.game-score-current {
    padding: .05em .2em;
    border: 10px solid !important;
    border-radius: 15%;
}

#game-score-overtext {
    font-size: .4em;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: .2em;
    z-index: 100;
}

.game-win-winner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/************** Help Screen **************/
#game-help-main {
    position: fixed;
    top: 3vh;
    left: 25vw;
    width: 50vw;
    border: 10px solid;
    border-image-source: var(--gradient);
    border-image-slice: 1;
    background: white;
    padding: 1em 1em .3em 1em;
    color: var(--secondary-text);
}
@media (max-width: 960px) {
    #game-help-main {
        top: 5vh;
        left: 20vw;
        font-size: .8em;
    }
}

#game-help-title {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 0;
}

#game-help-done {
    width: 5em;
    margin: .5em auto;
    text-decoration: none;
    font-weight: bold;
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.25em;
    border: 5px solid;
    border-image-source: var(--gradient);
    border-image-slice: 1;
    padding: 0 0;
}
@media (max-width: 960px) {
    #game-help-done {
        width: 6em;
    }
}


/************** Game Pieces **************/
.game-piece {
    height: 2.5em;
    width: 2.5em;
    border-radius: 20%;
    margin: 2px;
    box-sizing: border-box;
}

/* Player 1 Normal */
.game-piece-status-1 {
    background-color: var(--primary-light);
}

/* Player 2 Normal */
.game-piece-status-2 {
    background-color: var(--secondary-light);
}

/* Player 1 & 2 Solid */
.game-piece-status-3 {
    background-color: white;
    border: 15px solid var(--primary-light);
}
.game-piece-status-4 {
    background-color: white;
    border: 15px solid var(--secondary-light);
}
@media (max-width: 960px) {
    .game-piece-status-3, .game-piece-status-4 {
        border-width: 10px;
    }
}

/* Player 1 Selected */
.game-piece-status-5 {
    background-color: var(--primary-dark);
}

/* Player 2 Selected */
.game-piece-status-6 {
    background-color: var(--secondary-dark);
}

/* Player 1 Largest Member */
.game-piece-largest-1 {
    background-color: var(--primary);
}

/* Player 2 Largest Member */
.game-piece-largest-2 {
    background-color: var(--secondary);
}

/* Player 1 Solid & Largest */
.game-piece-status-3.game-piece-largest-1 {
    background: white;
    border-color: var(--primary);
}

/* Player 1 Solid & Largest */
.game-piece-status-4.game-piece-largest-2 {
    background: white;
    border-color: var(--secondary);
}

/* Player 1 Selected & Largest */
.game-piece-status-5.game-piece-largest-1 {
    background-color: var(--primary-dark);
}

/* Player 2 Selected & Largest */
.game-piece-status-6.game-piece-largest-2 {
    background-color: var(--secondary-dark);
}

/***** MULTIPLAYER *****/
#multiplayer-link {
    font-size: .6em;
    max-width: 45vw;
    overflow-wrap: break-word;
    margin: 20px auto 5px auto;
    font-weight: bold;
}

#multiplayer-link-copy {
    font-size: .6em;
    margin: 0;
}
@media (max-width: 960px) {
    #multiplayer-link-copy {
        margin-bottom: 20px;
    }
}


