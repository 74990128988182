.App {
  text-align: center;
  height: 100vh !important;
  width: 100vw !important;
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 0;
  overflow: hidden;
  height: 100vh !important;
  width: 100vw !important;
}

.app-grid {
  padding: 0;
  height: 100vh !important;
  width: 100vw !important;
}

.app-grid-item {
  padding: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
}
